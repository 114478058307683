@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #fff;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-link-color: #0d6efd;
  --bs-link-hover-color: #0a58ca;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: 1px solid;
  opacity: 0.25;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.5rem;
  }
}

h2, .h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2rem;
  }
}

h3, .h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.75rem;
  }
}

h4, .h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.5rem;
  }
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.1875em;
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: var(--bs-link-color);
  text-decoration: underline;
}
a:hover {
  color: var(--bs-link-hover-color);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.25rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c757d;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid var(--bs-border-color);
  border-radius: 0.375rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: #6c757d;
}

.container,
.container-fluid,
.container-xxxl,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}
@media (min-width: 1597px) {
  .container-xxxl, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1517px;
  }
}
.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: #212529;
  --bs-dropdown-bg: #fff;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: 0.375rem;
  --bs-dropdown-border-width: 1px;
  --bs-dropdown-inner-border-radius: calc(0.375rem - 1px);
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-dropdown-link-color: #212529;
  --bs-dropdown-link-hover-color: #1e2125;
  --bs-dropdown-link-hover-bg: #e9ecef;
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1597px) {
  .dropdown-menu-xxxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xxxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}
.dropdown-item.active, .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.875rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
}

.dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }

  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333333%;
  }

  .offset-sm-2 {
    margin-left: 16.66666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333333%;
  }

  .offset-sm-5 {
    margin-left: 41.66666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333333%;
  }

  .offset-sm-8 {
    margin-left: 66.66666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333333%;
  }

  .offset-sm-11 {
    margin-left: 91.66666667%;
  }

  .g-sm-0,
.gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .g-sm-0,
.gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .g-sm-1,
.gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-sm-1,
.gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-sm-2,
.gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-sm-2,
.gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-sm-3,
.gx-sm-3 {
    --bs-gutter-x: 1rem;
  }

  .g-sm-3,
.gy-sm-3 {
    --bs-gutter-y: 1rem;
  }

  .g-sm-4,
.gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-sm-4,
.gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-sm-5,
.gx-sm-5 {
    --bs-gutter-x: 3rem;
  }

  .g-sm-5,
.gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }

  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333333%;
  }

  .offset-md-2 {
    margin-left: 16.66666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333333%;
  }

  .offset-md-5 {
    margin-left: 41.66666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333333%;
  }

  .offset-md-8 {
    margin-left: 66.66666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333333%;
  }

  .offset-md-11 {
    margin-left: 91.66666667%;
  }

  .g-md-0,
.gx-md-0 {
    --bs-gutter-x: 0;
  }

  .g-md-0,
.gy-md-0 {
    --bs-gutter-y: 0;
  }

  .g-md-1,
.gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-md-1,
.gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-md-2,
.gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-md-2,
.gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-md-3,
.gx-md-3 {
    --bs-gutter-x: 1rem;
  }

  .g-md-3,
.gy-md-3 {
    --bs-gutter-y: 1rem;
  }

  .g-md-4,
.gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-md-4,
.gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-md-5,
.gx-md-5 {
    --bs-gutter-x: 3rem;
  }

  .g-md-5,
.gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }

  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333333%;
  }

  .offset-lg-2 {
    margin-left: 16.66666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333333%;
  }

  .offset-lg-5 {
    margin-left: 41.66666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333333%;
  }

  .offset-lg-8 {
    margin-left: 66.66666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333333%;
  }

  .offset-lg-11 {
    margin-left: 91.66666667%;
  }

  .g-lg-0,
.gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .g-lg-0,
.gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .g-lg-1,
.gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-lg-1,
.gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-lg-2,
.gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-lg-2,
.gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-lg-3,
.gx-lg-3 {
    --bs-gutter-x: 1rem;
  }

  .g-lg-3,
.gy-lg-3 {
    --bs-gutter-y: 1rem;
  }

  .g-lg-4,
.gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-lg-4,
.gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-lg-5,
.gx-lg-5 {
    --bs-gutter-x: 3rem;
  }

  .g-lg-5,
.gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }

  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xl-11 {
    margin-left: 91.66666667%;
  }

  .g-xl-0,
.gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .g-xl-0,
.gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .g-xl-1,
.gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xl-1,
.gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xl-2,
.gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xl-2,
.gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xl-3,
.gx-xl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xl-3,
.gy-xl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xl-4,
.gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xl-4,
.gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xl-5,
.gx-xl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xl-5,
.gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }

  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }

  .g-xxl-0,
.gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  .g-xxl-0,
.gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  .g-xxl-1,
.gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xxl-1,
.gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xxl-2,
.gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xxl-2,
.gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xxl-3,
.gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xxl-3,
.gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xxl-4,
.gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xxl-4,
.gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xxl-5,
.gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xxl-5,
.gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1597px) {
  .col-xxxl {
    flex: 1 0 0%;
  }

  .row-cols-xxxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xxxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xxxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xxxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xxxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xxxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xxxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xxxl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xxxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xxxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xxxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xxxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xxxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xxxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xxxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xxxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xxxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xxxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xxxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xxxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xxxl-0 {
    margin-left: 0;
  }

  .offset-xxxl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xxxl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xxxl-3 {
    margin-left: 25%;
  }

  .offset-xxxl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xxxl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xxxl-6 {
    margin-left: 50%;
  }

  .offset-xxxl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xxxl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xxxl-9 {
    margin-left: 75%;
  }

  .offset-xxxl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xxxl-11 {
    margin-left: 91.66666667%;
  }

  .g-xxxl-0,
.gx-xxxl-0 {
    --bs-gutter-x: 0;
  }

  .g-xxxl-0,
.gy-xxxl-0 {
    --bs-gutter-y: 0;
  }

  .g-xxxl-1,
.gx-xxxl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xxxl-1,
.gy-xxxl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xxxl-2,
.gx-xxxl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xxxl-2,
.gy-xxxl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xxxl-3,
.gx-xxxl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xxxl-3,
.gy-xxxl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xxxl-4,
.gx-xxxl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xxxl-4,
.gy-xxxl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xxxl-5,
.gx-xxxl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xxxl-5,
.gy-xxxl-5 {
    --bs-gutter-y: 3rem;
  }
}
.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: #fff;
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: 1px;
  --bs-modal-border-radius: 0.5rem;
  --bs-modal-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-modal-inner-border-radius: calc(0.5rem - 1px);
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: 1px;
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: 1px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}
.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * 0.5) calc(var(--bs-modal-header-padding-x) * 0.5);
  margin: calc(-0.5 * var(--bs-modal-header-padding-y)) calc(-0.5 * var(--bs-modal-header-padding-x)) calc(-0.5 * var(--bs-modal-header-padding-y)) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}
.modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * 0.5);
}

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }

  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
  }

  .modal-sm {
    --bs-modal-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    --bs-modal-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header,
.modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header,
.modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header,
.modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header,
.modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header,
.modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1596.98px) {
  .modal-fullscreen-xxxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxxl-down .modal-header,
.modal-fullscreen-xxxl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xxxl-down .modal-body {
    overflow-y: auto;
  }
}
.offcanvas, .offcanvas-xxxl, .offcanvas-xxl, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: ;
  --bs-offcanvas-bg: #fff;
  --bs-offcanvas-border-width: 1px;
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}
@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-sm .offcanvas-header {
    display: none;
  }
  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}
@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-md .offcanvas-header {
    display: none;
  }
  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}
@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-lg .offcanvas-header {
    display: none;
  }
  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}
@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xl .offcanvas-header {
    display: none;
  }
  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
    visibility: visible;
  }
}
@media (min-width: 1400px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xxl .offcanvas-header {
    display: none;
  }
  .offcanvas-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1596.98px) {
  .offcanvas-xxxl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 1596.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxxl {
    transition: none;
  }
}
@media (max-width: 1596.98px) {
  .offcanvas-xxxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 1596.98px) {
  .offcanvas-xxxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 1596.98px) {
  .offcanvas-xxxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 1596.98px) {
  .offcanvas-xxxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 1596.98px) {
  .offcanvas-xxxl.showing, .offcanvas-xxxl.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 1596.98px) {
  .offcanvas-xxxl.showing, .offcanvas-xxxl.hiding, .offcanvas-xxxl.show {
    visibility: visible;
  }
}
@media (min-width: 1597px) {
  .offcanvas-xxxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xxxl .offcanvas-header {
    display: none;
  }
  .offcanvas-xxxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}
.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(-100%);
}
.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(100%);
}
.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(-100%);
}
.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(100%);
}
.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}
.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}
.offcanvas-header .btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * 0.5) calc(var(--bs-offcanvas-padding-x) * 0.5);
  margin-top: calc(-0.5 * var(--bs-offcanvas-padding-y));
  margin-right: calc(-0.5 * var(--bs-offcanvas-padding-x));
  margin-bottom: calc(-0.5 * var(--bs-offcanvas-padding-y));
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.accordion {
  --bs-accordion-color: #212529;
  --bs-accordion-bg: #fff;
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: 1px;
  --bs-accordion-border-radius: 0.375rem;
  --bs-accordion-inner-border-radius: calc(0.375rem - 1px);
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: #212529;
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #86b7fe;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: #0c63e4;
  --bs-accordion-active-bg: #e7f1ff;
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 1rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}
.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  outline: 0;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}
.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 0;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-1 {
  --bs-border-width: 1px;
}

.border-2 {
  --bs-border-width: 2px;
}

.border-3 {
  --bs-border-width: 3px;
}

.border-4 {
  --bs-border-width: 4px;
}

.border-5 {
  --bs-border-width: 5px;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: #6c757d !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-2xl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }

  .float-sm-end {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mt-sm-3 {
    margin-top: 1rem !important;
  }

  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-5 {
    margin-top: 3rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: 0.25rem !important;
  }

  .me-sm-2 {
    margin-right: 0.5rem !important;
  }

  .me-sm-3 {
    margin-right: 1rem !important;
  }

  .me-sm-4 {
    margin-right: 1.5rem !important;
  }

  .me-sm-5 {
    margin-right: 3rem !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }

  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }

  .ms-sm-3 {
    margin-left: 1rem !important;
  }

  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }

  .ms-sm-5 {
    margin-left: 3rem !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pt-sm-3 {
    padding-top: 1rem !important;
  }

  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-5 {
    padding-top: 3rem !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pe-sm-3 {
    padding-right: 1rem !important;
  }

  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pe-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }

  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }

  .ps-sm-3 {
    padding-left: 1rem !important;
  }

  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }

  .ps-sm-5 {
    padding-left: 3rem !important;
  }

  .gap-sm-0 {
    gap: 0 !important;
  }

  .gap-sm-1 {
    gap: 0.25rem !important;
  }

  .gap-sm-2 {
    gap: 0.5rem !important;
  }

  .gap-sm-3 {
    gap: 1rem !important;
  }

  .gap-sm-4 {
    gap: 1.5rem !important;
  }

  .gap-sm-5 {
    gap: 3rem !important;
  }

  .text-sm-start {
    text-align: left !important;
  }

  .text-sm-end {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }

  .float-md-end {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: 0.25rem !important;
  }

  .mt-md-2 {
    margin-top: 0.5rem !important;
  }

  .mt-md-3 {
    margin-top: 1rem !important;
  }

  .mt-md-4 {
    margin-top: 1.5rem !important;
  }

  .mt-md-5 {
    margin-top: 3rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1 {
    margin-right: 0.25rem !important;
  }

  .me-md-2 {
    margin-right: 0.5rem !important;
  }

  .me-md-3 {
    margin-right: 1rem !important;
  }

  .me-md-4 {
    margin-right: 1.5rem !important;
  }

  .me-md-5 {
    margin-right: 3rem !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-md-3 {
    margin-bottom: 1rem !important;
  }

  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-5 {
    margin-bottom: 3rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1 {
    margin-left: 0.25rem !important;
  }

  .ms-md-2 {
    margin-left: 0.5rem !important;
  }

  .ms-md-3 {
    margin-left: 1rem !important;
  }

  .ms-md-4 {
    margin-left: 1.5rem !important;
  }

  .ms-md-5 {
    margin-left: 3rem !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: 0.25rem !important;
  }

  .pt-md-2 {
    padding-top: 0.5rem !important;
  }

  .pt-md-3 {
    padding-top: 1rem !important;
  }

  .pt-md-4 {
    padding-top: 1.5rem !important;
  }

  .pt-md-5 {
    padding-top: 3rem !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1 {
    padding-right: 0.25rem !important;
  }

  .pe-md-2 {
    padding-right: 0.5rem !important;
  }

  .pe-md-3 {
    padding-right: 1rem !important;
  }

  .pe-md-4 {
    padding-right: 1.5rem !important;
  }

  .pe-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-md-3 {
    padding-bottom: 1rem !important;
  }

  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-5 {
    padding-bottom: 3rem !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1 {
    padding-left: 0.25rem !important;
  }

  .ps-md-2 {
    padding-left: 0.5rem !important;
  }

  .ps-md-3 {
    padding-left: 1rem !important;
  }

  .ps-md-4 {
    padding-left: 1.5rem !important;
  }

  .ps-md-5 {
    padding-left: 3rem !important;
  }

  .gap-md-0 {
    gap: 0 !important;
  }

  .gap-md-1 {
    gap: 0.25rem !important;
  }

  .gap-md-2 {
    gap: 0.5rem !important;
  }

  .gap-md-3 {
    gap: 1rem !important;
  }

  .gap-md-4 {
    gap: 1.5rem !important;
  }

  .gap-md-5 {
    gap: 3rem !important;
  }

  .text-md-start {
    text-align: left !important;
  }

  .text-md-end {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }

  .float-lg-end {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mt-lg-3 {
    margin-top: 1rem !important;
  }

  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-5 {
    margin-top: 3rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: 0.25rem !important;
  }

  .me-lg-2 {
    margin-right: 0.5rem !important;
  }

  .me-lg-3 {
    margin-right: 1rem !important;
  }

  .me-lg-4 {
    margin-right: 1.5rem !important;
  }

  .me-lg-5 {
    margin-right: 3rem !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }

  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }

  .ms-lg-3 {
    margin-left: 1rem !important;
  }

  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }

  .ms-lg-5 {
    margin-left: 3rem !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pt-lg-3 {
    padding-top: 1rem !important;
  }

  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-5 {
    padding-top: 3rem !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pe-lg-3 {
    padding-right: 1rem !important;
  }

  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pe-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }

  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }

  .ps-lg-3 {
    padding-left: 1rem !important;
  }

  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }

  .ps-lg-5 {
    padding-left: 3rem !important;
  }

  .gap-lg-0 {
    gap: 0 !important;
  }

  .gap-lg-1 {
    gap: 0.25rem !important;
  }

  .gap-lg-2 {
    gap: 0.5rem !important;
  }

  .gap-lg-3 {
    gap: 1rem !important;
  }

  .gap-lg-4 {
    gap: 1.5rem !important;
  }

  .gap-lg-5 {
    gap: 3rem !important;
  }

  .text-lg-start {
    text-align: left !important;
  }

  .text-lg-end {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }

  .float-xl-end {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xl-3 {
    margin-top: 1rem !important;
  }

  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-5 {
    margin-top: 3rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xl-3 {
    margin-right: 1rem !important;
  }

  .me-xl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xl-5 {
    margin-right: 3rem !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xl-3 {
    margin-left: 1rem !important;
  }

  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xl-5 {
    margin-left: 3rem !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xl-3 {
    padding-top: 1rem !important;
  }

  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-5 {
    padding-top: 3rem !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xl-3 {
    padding-right: 1rem !important;
  }

  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xl-3 {
    padding-left: 1rem !important;
  }

  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xl-5 {
    padding-left: 3rem !important;
  }

  .gap-xl-0 {
    gap: 0 !important;
  }

  .gap-xl-1 {
    gap: 0.25rem !important;
  }

  .gap-xl-2 {
    gap: 0.5rem !important;
  }

  .gap-xl-3 {
    gap: 1rem !important;
  }

  .gap-xl-4 {
    gap: 1.5rem !important;
  }

  .gap-xl-5 {
    gap: 3rem !important;
  }

  .text-xl-start {
    text-align: left !important;
  }

  .text-xl-end {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }

  .float-xxl-end {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: grid !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .order-xxl-first {
    order: -1 !important;
  }

  .order-xxl-0 {
    order: 0 !important;
  }

  .order-xxl-1 {
    order: 1 !important;
  }

  .order-xxl-2 {
    order: 2 !important;
  }

  .order-xxl-3 {
    order: 3 !important;
  }

  .order-xxl-4 {
    order: 4 !important;
  }

  .order-xxl-5 {
    order: 5 !important;
  }

  .order-xxl-last {
    order: 6 !important;
  }

  .m-xxl-0 {
    margin: 0 !important;
  }

  .m-xxl-1 {
    margin: 0.25rem !important;
  }

  .m-xxl-2 {
    margin: 0.5rem !important;
  }

  .m-xxl-3 {
    margin: 1rem !important;
  }

  .m-xxl-4 {
    margin: 1.5rem !important;
  }

  .m-xxl-5 {
    margin: 3rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xxl-3 {
    margin-top: 1rem !important;
  }

  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xxl-5 {
    margin-top: 3rem !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .me-xxl-0 {
    margin-right: 0 !important;
  }

  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xxl-3 {
    margin-right: 1rem !important;
  }

  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xxl-5 {
    margin-right: 3rem !important;
  }

  .me-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .ms-xxl-0 {
    margin-left: 0 !important;
  }

  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xxl-3 {
    margin-left: 1rem !important;
  }

  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xxl-5 {
    margin-left: 3rem !important;
  }

  .ms-xxl-auto {
    margin-left: auto !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-1 {
    padding: 0.25rem !important;
  }

  .p-xxl-2 {
    padding: 0.5rem !important;
  }

  .p-xxl-3 {
    padding: 1rem !important;
  }

  .p-xxl-4 {
    padding: 1.5rem !important;
  }

  .p-xxl-5 {
    padding: 3rem !important;
  }

  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xxl-3 {
    padding-top: 1rem !important;
  }

  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xxl-5 {
    padding-top: 3rem !important;
  }

  .pe-xxl-0 {
    padding-right: 0 !important;
  }

  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xxl-3 {
    padding-right: 1rem !important;
  }

  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xxl-5 {
    padding-right: 3rem !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xxl-0 {
    padding-left: 0 !important;
  }

  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xxl-3 {
    padding-left: 1rem !important;
  }

  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xxl-5 {
    padding-left: 3rem !important;
  }

  .gap-xxl-0 {
    gap: 0 !important;
  }

  .gap-xxl-1 {
    gap: 0.25rem !important;
  }

  .gap-xxl-2 {
    gap: 0.5rem !important;
  }

  .gap-xxl-3 {
    gap: 1rem !important;
  }

  .gap-xxl-4 {
    gap: 1.5rem !important;
  }

  .gap-xxl-5 {
    gap: 3rem !important;
  }

  .text-xxl-start {
    text-align: left !important;
  }

  .text-xxl-end {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1597px) {
  .float-xxxl-start {
    float: left !important;
  }

  .float-xxxl-end {
    float: right !important;
  }

  .float-xxxl-none {
    float: none !important;
  }

  .d-xxxl-inline {
    display: inline !important;
  }

  .d-xxxl-inline-block {
    display: inline-block !important;
  }

  .d-xxxl-block {
    display: block !important;
  }

  .d-xxxl-grid {
    display: grid !important;
  }

  .d-xxxl-table {
    display: table !important;
  }

  .d-xxxl-table-row {
    display: table-row !important;
  }

  .d-xxxl-table-cell {
    display: table-cell !important;
  }

  .d-xxxl-flex {
    display: flex !important;
  }

  .d-xxxl-inline-flex {
    display: inline-flex !important;
  }

  .d-xxxl-none {
    display: none !important;
  }

  .flex-xxxl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xxxl-row {
    flex-direction: row !important;
  }

  .flex-xxxl-column {
    flex-direction: column !important;
  }

  .flex-xxxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xxxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xxxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxxl-center {
    justify-content: center !important;
  }

  .justify-content-xxxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxxl-around {
    justify-content: space-around !important;
  }

  .justify-content-xxxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xxxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxxl-center {
    align-items: center !important;
  }

  .align-items-xxxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxxl-center {
    align-content: center !important;
  }

  .align-content-xxxl-between {
    align-content: space-between !important;
  }

  .align-content-xxxl-around {
    align-content: space-around !important;
  }

  .align-content-xxxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxxl-auto {
    align-self: auto !important;
  }

  .align-self-xxxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxxl-center {
    align-self: center !important;
  }

  .align-self-xxxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxxl-stretch {
    align-self: stretch !important;
  }

  .order-xxxl-first {
    order: -1 !important;
  }

  .order-xxxl-0 {
    order: 0 !important;
  }

  .order-xxxl-1 {
    order: 1 !important;
  }

  .order-xxxl-2 {
    order: 2 !important;
  }

  .order-xxxl-3 {
    order: 3 !important;
  }

  .order-xxxl-4 {
    order: 4 !important;
  }

  .order-xxxl-5 {
    order: 5 !important;
  }

  .order-xxxl-last {
    order: 6 !important;
  }

  .m-xxxl-0 {
    margin: 0 !important;
  }

  .m-xxxl-1 {
    margin: 0.25rem !important;
  }

  .m-xxxl-2 {
    margin: 0.5rem !important;
  }

  .m-xxxl-3 {
    margin: 1rem !important;
  }

  .m-xxxl-4 {
    margin: 1.5rem !important;
  }

  .m-xxxl-5 {
    margin: 3rem !important;
  }

  .m-xxxl-auto {
    margin: auto !important;
  }

  .mx-xxxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xxxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xxxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xxxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xxxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xxxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xxxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xxxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xxxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xxxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xxxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xxxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xxxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxxl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xxxl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xxxl-3 {
    margin-top: 1rem !important;
  }

  .mt-xxxl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xxxl-5 {
    margin-top: 3rem !important;
  }

  .mt-xxxl-auto {
    margin-top: auto !important;
  }

  .me-xxxl-0 {
    margin-right: 0 !important;
  }

  .me-xxxl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xxxl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xxxl-3 {
    margin-right: 1rem !important;
  }

  .me-xxxl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xxxl-5 {
    margin-right: 3rem !important;
  }

  .me-xxxl-auto {
    margin-right: auto !important;
  }

  .mb-xxxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxxl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xxxl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xxxl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xxxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xxxl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xxxl-auto {
    margin-bottom: auto !important;
  }

  .ms-xxxl-0 {
    margin-left: 0 !important;
  }

  .ms-xxxl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xxxl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xxxl-3 {
    margin-left: 1rem !important;
  }

  .ms-xxxl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xxxl-5 {
    margin-left: 3rem !important;
  }

  .ms-xxxl-auto {
    margin-left: auto !important;
  }

  .p-xxxl-0 {
    padding: 0 !important;
  }

  .p-xxxl-1 {
    padding: 0.25rem !important;
  }

  .p-xxxl-2 {
    padding: 0.5rem !important;
  }

  .p-xxxl-3 {
    padding: 1rem !important;
  }

  .p-xxxl-4 {
    padding: 1.5rem !important;
  }

  .p-xxxl-5 {
    padding: 3rem !important;
  }

  .px-xxxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xxxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xxxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xxxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xxxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xxxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-xxxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xxxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xxxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xxxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xxxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xxxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxxl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xxxl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xxxl-3 {
    padding-top: 1rem !important;
  }

  .pt-xxxl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xxxl-5 {
    padding-top: 3rem !important;
  }

  .pe-xxxl-0 {
    padding-right: 0 !important;
  }

  .pe-xxxl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xxxl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xxxl-3 {
    padding-right: 1rem !important;
  }

  .pe-xxxl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xxxl-5 {
    padding-right: 3rem !important;
  }

  .pb-xxxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxxl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xxxl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xxxl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xxxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xxxl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xxxl-0 {
    padding-left: 0 !important;
  }

  .ps-xxxl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xxxl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xxxl-3 {
    padding-left: 1rem !important;
  }

  .ps-xxxl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xxxl-5 {
    padding-left: 3rem !important;
  }

  .gap-xxxl-0 {
    gap: 0 !important;
  }

  .gap-xxxl-1 {
    gap: 0.25rem !important;
  }

  .gap-xxxl-2 {
    gap: 0.5rem !important;
  }

  .gap-xxxl-3 {
    gap: 1rem !important;
  }

  .gap-xxxl-4 {
    gap: 1.5rem !important;
  }

  .gap-xxxl-5 {
    gap: 3rem !important;
  }

  .text-xxxl-start {
    text-align: left !important;
  }

  .text-xxxl-end {
    text-align: right !important;
  }

  .text-xxxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }

  .fs-2 {
    font-size: 2rem !important;
  }

  .fs-3 {
    font-size: 1.75rem !important;
  }

  .fs-4 {
    font-size: 1.5rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}
:root {
  --h-height: 110px;
  --f-height: 150px;
}
@media (max-width: 1399.98px) {
  :root {
    --h-height: 100px;
  }
}
@media (max-width: 1199.98px) {
  :root {
    --h-height: 90px;
  }
}
@media (max-width: 991.98px) {
  :root {
    --h-height: 70px;
    --f-height: 65px;
  }
}
@media (max-width: 575.98px) {
  :root {
    --h-height: 45px;
  }
}

/* mixins */
/* Typography */
a:not(.tele),
a:hover:not(.tele),
a:focus:not(.tele),
a:active:not(.tele) {
  color: inherit;
  outline: none;
  text-decoration: none;
  cursor: pointer;
}

.tele {
  outline: none;
  text-decoration: none;
}

h1, .h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
p,
label,
span,
a,
button,
form,
fieldset,
legend,
select,
input,
textarea,
div,
blockquote,
cite,
figure,
figcaption,
table,
ul,
ol,
dl,
li,
address {
  line-height: 120%;
  margin-bottom: 0px;
}

h1, .h1,
h2,
.h2,
h4,
.h4 {
  font-family: "Racama";
}

h1,
.h1 {
  font-size: 3.125em;
  font-weight: 400;
  margin-bottom: 1em;
}
@media (max-width: 1399.98px) {
  h1,
.h1 {
    font-size: 2.7em;
  }
}
@media (max-width: 991.98px) {
  h1,
.h1 {
    font-size: 2em;
  }
}

h2,
.h2 {
  font-size: 2.5em;
  font-weight: 400;
  margin-bottom: 0.5em;
}
@media (max-width: 1399.98px) {
  h2,
.h2 {
    font-size: 2em;
  }
}
@media (max-width: 767.98px) {
  h2,
.h2 {
    font-size: 1.75em;
  }
}

h3, .h3 {
  font-size: 1.875em;
  font-weight: 500;
  margin-bottom: 0.5em;
}
@media (max-width: 1399.98px) {
  h3, .h3 {
    font-size: 1.5em;
  }
}

h4, .h4 {
  font-size: 1.5em;
  font-weight: 700;
  margin-bottom: 0.8em;
}
@media (max-width: 1399.98px) {
  h4, .h4 {
    font-size: 1.35em;
  }
}

h5, .h5 {
  font-size: 1.25em;
  font-weight: 500;
  margin-bottom: 1em;
}
@media (max-width: 1399.98px) {
  h5, .h5 {
    font-size: 1.2em;
  }
}

h6, .h6 {
  font-size: 1em;
  font-weight: 400;
}

.fs-08 {
  font-size: 0.8em;
}
.fs-09 {
  font-size: 0.9em;
}
.fs-10 {
  font-size: 1em;
}
.fs-11 {
  font-size: 1.1em;
}
.fs-12 {
  font-size: 1.2em;
}
.fs-13 {
  font-size: 1.3em;
}
.fs-14 {
  font-size: 1.4em;
}
.fs-15 {
  font-size: 1.5em;
}
.fs-16 {
  font-size: 1.6em;
}
.fs-17 {
  font-size: 1.7em;
}
.fs-18 {
  font-size: 1.8em;
}
.fs-19 {
  font-size: 1.9em;
}
.fs-20 {
  font-size: 2em;
}
.fs-25 {
  font-size: 2.5em;
}
.fs-30 {
  font-size: 3em;
}
.fs-35 {
  font-size: 3.5em;
}
.fs-40 {
  font-size: 4em;
}

.fw-3 {
  font-weight: 300;
}
.fw-4 {
  font-weight: 400;
}
.fw-5 {
  font-weight: 500;
}
.fw-6 {
  font-weight: 600;
}
.fw-7 {
  font-weight: 700;
}
.fw-8 {
  font-weight: 800;
}
.fw-9 {
  font-weight: 900;
}

.gray {
  color: #A3A3A3;
}

.main-font {
  font-family: "Montserrat";
}

/* Form elements */
input,
textarea {
  background-color: #FFFFFF;
  border: 1px solid #F6F6F6;
  border-radius: 0.625em;
  padding: 0.725em 1.65em;
  width: 100%;
  outline: none;
  display: block;
  transition: 300ms ease-in-out;
}
input:-moz-placeholder, input::-moz-placeholder, input::-webkit-input-placeholder, input:-ms-input-placeholder, input::-ms-input-placeholder,
textarea:-moz-placeholder,
textarea::-moz-placeholder,
textarea::-webkit-input-placeholder,
textarea:-ms-input-placeholder,
textarea::-ms-input-placeholder {
  color: #A3A3A3;
  font-weight: 400;
}
input::placeholder,
textarea::placeholder {
  color: #A3A3A3;
  font-weight: 400;
}
@media (max-width: 575.98px) {
  input,
textarea {
    padding: 1em 1.65em;
  }
}

input[type=radio] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  outline: none;
  width: 20px;
  height: 20px;
  border: 1px solid #2C2C2C;
  background-color: transparent;
  border-radius: 50%;
  box-shadow: none;
  padding: 0px;
  margin: 0px;
  transition: 300ms ease-in-out;
  position: relative;
}
input[type=radio]::before {
  content: "";
  position: absolute;
  top: 4px;
  left: 4px;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #4FAC76;
  transition: 300ms ease-in-out;
  opacity: 0;
}
input[type=radio]:checked::before {
  opacity: 1;
}
input[type=checkbox] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  outline: none;
  width: 20px;
  height: 20px;
  border: 1px solid #2C2C2C;
  background-color: #FFFFFF;
  background-image: url(imgs/icons/mark.svg);
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 0.25em !important;
  box-shadow: none;
  padding: 0px;
  margin: 0px;
  transition: 300ms ease-in-out;
  position: relative;
}
input[type=checkbox]:checked {
  background-color: #4FAC76;
  border: 1px solid #4FAC76;
}

label {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
}
label span {
  flex: 1;
}
label.pill div {
  color: #2C2C2C;
  background-color: rgba(79, 172, 118, 0.2);
  font-weight: 500 !important;
  padding: 0.65em !important;
}
label.pill input:checked + div {
  color: #FFFFFF;
  background-color: #4fac76;
}

select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  outline: none;
  background-color: transparent;
  background-image: url(/imgs/icons/chevron-down.svg);
  background-repeat: no-repeat;
  background-position: calc(100% - 0.5em) center;
  border: none;
  padding-right: 2em !important;
}

button {
  background: none;
  border: none;
  padding: unset;
  display: block;
  font-family: inherit;
  outline: none;
  color: inherit;
}

.btn, .btn-6, .btn-5, .btn-4, .btn-3, .btn-2, .btn-1, label.pill div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  padding: 0.7em 1.2em;
  text-align: center;
  transition: 300ms ease-in-out;
  width: fit-content;
  border-radius: 0.625em;
  font-weight: 600;
  /* зеленая кнопка */
  /* кнопка с темной обводкой */
  /* темная кнопка */
  /* белая кнопка */
  /* светло-зеленая кнопка */
  /* серая кнопка */
}
@media (max-width: 575.98px) {
  .btn, .btn-6, .btn-5, .btn-4, .btn-3, .btn-2, .btn-1, label.pill div {
    padding: 1em 1.65em;
  }
}
.btn-none {
  padding: 0px;
  font-weight: 400;
}
.btn-1 {
  color: #FFFFFF;
  background-color: #4FAC76;
}
.btn-1:hover, .btn-1:focus, .btn-1:active, .btn-1.active {
  color: #FFFFFF;
  background-color: #236840;
}
.btn-2 {
  color: #000000;
  border: 1px solid #2C2C2C;
  background-color: transparent;
}
.btn-2:hover, .btn-2:focus, .btn-2:active, .btn-2.active {
  color: #FFFFFF;
  border: 1px solid #4FAC76;
  background-color: #4FAC76;
}
.btn-3 {
  color: #FFFFFF;
  background-color: #000000;
  border: 1px solid #000000;
}
.btn-3:hover, .btn-3:focus, .btn-3:active, .btn-3.active {
  color: #000000;
  border: 1px solid #000000;
  background-color: #FFFFFF;
}
.btn-4 {
  color: #000000;
  background-color: #FFFFFF;
  font-weight: 400;
}
.btn-4:hover, .btn-4:focus, .btn-4:active, .btn-4.active {
  color: #000000;
  background-color: #b6dec7;
}
.btn-5 {
  color: #2C2C2C;
  background-color: rgba(79, 172, 118, 0.2);
  font-weight: 500;
}
.btn-5:hover, .btn-5:focus, .btn-5:active, .btn-5.active {
  color: #FFFFFF;
  background-color: #4fac76;
}
.btn-6 {
  color: #FFFFFF;
  background-color: #A3A3A3;
}
.btn-6:hover, .btn-6:focus, .btn-6:active, .btn-6.active {
  color: #FFFFFF;
  background-color: #4FAC76;
}
.btn[disabled], label.pill div[disabled], [disabled].btn-1, [disabled].btn-2, [disabled].btn-3, [disabled].btn-4, [disabled].btn-5, [disabled].btn-6 {
  pointer-events: none;
  filter: saturate(0%);
}

/* Blocks */
.flex-1 {
  flex: 1;
}

.mb-6 {
  margin-bottom: 8em;
}
@media (max-width: 991.98px) {
  .mb-6 {
    margin-bottom: 6em;
  }
}
@media (max-width: 575.98px) {
  .mb-6 {
    margin-bottom: 4em;
  }
}

@media (max-width: 575.98px) {
  .w-xs-100 {
    width: 100% !important;
  }
}

.breadcrumbs {
  margin-top: 1em;
  margin-bottom: 2em;
}
.breadcrumbs ul {
  list-style: none;
  padding-left: 0px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  color: #A3A3A3;
  font-weight: 500;
}
.breadcrumbs ul li.divider {
  margin: 0 0.5em;
  display: flex;
}
.breadcrumbs ul li.divider svg {
  stroke-width: 3px;
  font-size: 0.8em;
}

.star-rating {
  color: #4FAC76;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  font-size: 1.6em;
}
.star-rating svg:not(:first-child) {
  margin-left: 0.25em;
}

.dropdown-toggle::after {
  background: url(/imgs/chevron-down.svg) no-repeat center;
  background-size: contain;
  border: none;
  width: 8px;
  height: 6px;
}
.dropdown-menu {
  --bs-dropdown-link-active-bg: #4FAC76;
}

.accordion {
  --bs-accordion-color: $dark;
  --bs-accordion-active-color: $black;
  --bs-accordion-border-color: transparent;
  --bs-accordion-border-width: 0px;
  --bs-accordion-border-radius: 0px;
  --bs-accordion-inner-border-radius: 0px;
  --bs-accordion-btn-padding-x: 0px;
  --bs-accordion-btn-padding-y: .75em;
  --bs-accordion-btn-focus-border-color: transparent;
  --bs-accordion-btn-focus-box-shadow: none;
  --bs-accordion-active-bg: transparent;
  --bs-accordion-btn-icon: url(imgs/icons/chevron-down.svg);
  --bs-accordion-btn-active-icon: url(imgs/icons/chevron-down.svg);
  --bs-accordion-btn-icon-width: .75em;
  --bs-accordion-body-padding-x: 0px;
  --bs-accordion-body-padding-y: 0px;
  border-bottom: 1px solid #F6F6F6 !important;
}
@media (max-width: 991.98px) {
  .accordion {
    --bs-accordion-btn-padding-y: 1em;
  }
}
.accordion-header {
  border-top: 1px solid #F6F6F6 !important;
}
.accordion-button {
  font-size: inherit;
  font-weight: inherit;
}
.accordion-body {
  padding: 0px 0px 1em;
}

.devs {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}
.devs img {
  width: 2.25em;
}

.product {
  background-color: #FFFFFF;
  padding: 0.75em;
  border-radius: 1.25em;
  font-size: 1.25em;
  position: relative;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  isolation: isolate;
  transform: translateZ(0);
}
@media (max-width: 1199.98px) {
  .product {
    font-size: 1.1em;
  }
}
.product::before {
  content: "";
  position: absolute;
  top: 0%;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 0;
  background: url(/imgs/bg/bg-gradient.jpg) no-repeat center;
  background-size: cover;
  opacity: 1;
  transition: 300ms ease-in-out;
}
.product:hover::before {
  opacity: 1;
}
.product img {
  --height: 250px;
  position: relative;
  z-index: 1;
  background-color: #FFFFFF;
  width: 100%;
  height: var(--height);
  border-radius: 1.25em;
  object-fit: scale-down;
  object-position: center;
  box-shadow: 0px 8px 10px rgba(141, 141, 141, 0.05);
}
@media (max-width: 1399.98px) {
  .product img {
    --height: 230px;
  }
}
@media (max-width: 1199.98px) {
  .product img {
    --height: 200px;
  }
}
@media (max-width: 575.98px) {
  .product img {
    --height: 40vw;
  }
}
.product figcaption {
  position: relative;
  z-index: 1;
  margin-top: 1em;
}
.product figcaption h6, .product figcaption .h6 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.2em;
  height: 2.4em;
}
@media (max-width: 575.98px) {
  .product figcaption button {
    width: 100%;
  }
}
.product-bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
}
@media (max-width: 575.98px) {
  .product-bottom {
    display: block;
  }
}

.category-card {
  position: relative;
}
.category-card figcaption {
  margin-bottom: 2em;
}
@media (max-width: 1399.98px) {
  .category-card figcaption {
    margin-bottom: 1.5em;
  }
}
@media (max-width: 1199.98px) {
  .category-card figcaption {
    margin-bottom: 1em;
  }
}
@media (max-width: 575.98px) {
  .category-card figcaption {
    margin-bottom: 0.5em;
  }
}
.category-card figcaption h4, .category-card figcaption .h4 {
  margin-bottom: 0px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.2em;
  height: 2.4em;
}
.category-card img {
  width: 100%;
  height: 320px;
  object-fit: contain;
  background-color: #F6F6F6;
  border-radius: 1.25em;
  box-shadow: 0px 8px 10px rgba(141, 141, 141, 0.05);
}
@media (max-width: 1596.98px) {
  .category-card img {
    height: 280px;
  }
}
@media (max-width: 1399.98px) {
  .category-card img {
    height: 250px;
  }
}
@media (max-width: 1199.98px) {
  .category-card img {
    height: 200px;
  }
}
@media (max-width: 575.98px) {
  .category-card img {
    height: 40vw;
  }
}
.category-card.cap img {
  margin-top: 3.74em;
  padding: 1em;
}

.offer {
  margin-top: 10em;
  background-color: #5A9BFF;
  padding: 1em 0.75em 0.75em 23%;
  color: #FFFFFF;
  border-radius: 1.25em;
  position: relative;
  max-width: 300px;
  margin-left: auto;
}
@media (max-width: 1399.98px) {
  .offer {
    padding: 0.5em 0.5em 0.5em 32%;
    margin-top: 9em;
    font-size: 0.9em;
  }
}
@media (max-width: 1199.98px) {
  .offer {
    font-size: 0.7em;
    margin-top: 7em;
  }
}
@media (max-width: 991.98px) {
  .offer {
    max-width: 100%;
    font-size: 1.25em;
    margin-bottom: 2em;
    padding: 1em 1em 1em 40%;
  }
}
@media (max-width: 767.98px) {
  .offer {
    margin-top: 3em;
  }
}
@media (max-width: 575.98px) {
  .offer {
    font-size: 1em;
    padding: 1em 1em 1em 37%;
  }
}
.offer::before {
  content: "";
  background: url(/imgs/bg/blot2.svg) no-repeat;
  background-size: 100% 100%;
  background-position: left bottom;
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 108%;
  height: 113%;
  z-index: 1;
}
@media (max-width: 991.98px) {
  .offer::before {
    background: url(/imgs/bg/blot.svg) no-repeat;
    background-size: 100% 100%;
    background-position: right bottom;
    left: 25%;
    bottom: -5%;
    width: 78%;
    height: 115%;
  }
}
.offer .text {
  position: relative;
  z-index: 10;
}
.offer .text h3, .offer .text .h3 {
  font-size: 1.75em;
}
.offer img {
  position: absolute;
  z-index: 5;
  top: -2em;
  left: -20%;
  width: 50%;
  height: auto;
}
@media (max-width: 1399.98px) {
  .offer img {
    left: -10%;
    width: 45%;
  }
}
@media (max-width: 991.98px) {
  .offer img {
    bottom: 0px;
    left: 5%;
    width: 30%;
    height: calc(100% + 7em);
    top: unset;
    object-fit: cover;
    object-position: center top;
  }
}
@media (max-width: 767.98px) {
  .offer img {
    height: calc(100% + 3em);
  }
}

body {
  margin: 0;
  font-family: "Montserrat", "Arial", "Tahoma", "Verdana";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  overflow-x: hidden;
  position: relative;
}
@media (max-width: 767.98px) {
  body {
    font-size: 14px;
  }
}
@media (max-width: 575.98px) {
  body {
    font-size: 12px;
  }
}

main {
  min-height: calc(100vh - var(--h-height) - var(--f-height));
  padding-bottom: 0.5em;
}
@media (max-width: 991.98px) {
  main {
    padding: var(--h-height) 0px var(--f-height);
  }
}

header {
  position: relative;
  z-index: 1050;
  height: var(--h-height);
  background-color: transparent;
}
@media (max-width: 991.98px) {
  header {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    background-color: #FFFFFF;
    box-shadow: 0px 8px 10px rgba(141, 141, 141, 0.05);
  }
}
header .logo {
  display: block;
  margin-right: 1em;
  margin-bottom: 0.6em;
}
@media (max-width: 991.98px) {
  header .logo {
    margin-bottom: 0em;
  }
}
header .logo img {
  height: 40px;
}
@media (max-width: 575.98px) {
  header .logo img {
    height: 35px;
  }
}
header .menu {
  list-style: none;
  padding-left: 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}
header .menu li:not(:first-child) {
  margin-left: 4em;
}
@media (max-width: 1596.98px) {
  header .menu li:not(:first-child) {
    margin-left: 2em;
  }
}
@media (max-width: 1399.98px) {
  header .menu li:not(:first-child) {
    margin-left: 1.5em;
  }
}
header .menu li a {
  color: #000000;
  transition: 300ms ease-in-out;
  font-size: 14px;
}
header .menu li a:hover, header .menu li a:focus, header .menu li a:active, header .menu li a.active {
  color: #4FAC76;
}
header .phone {
  font-size: 1.5em;
  font-weight: 500;
}
@media (max-width: 1399.98px) {
  header .phone {
    font-size: 1.1em;
  }
}
@media (max-width: 991.98px) {
  header .phone {
    display: none;
  }
}
header .cart {
  display: flex;
  font-size: 1.8em;
}
@media (max-width: 1399.98px) {
  header .cart {
    font-size: 1.5em;
  }
}
@media (max-width: 991.98px) {
  header .cart {
    display: none;
  }
}
header .search-icon {
  font-size: 1.4em;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}
@media (max-width: 991.98px) {
  header .search-icon {
    color: #A3A3A3;
    font-size: 1.75em;
  }
}
header .btn-menu {
  background-color: #4FAC76;
  color: #FFFFFF;
  margin-left: 1.5em;
  font-size: 1.75em;
  padding: 0.15em;
  border-radius: 5px;
  display: none;
}
@media (max-width: 991.98px) {
  header .btn-menu {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
  }
}

.form-search {
  position: relative;
  width: 250px;
}
@media (max-width: 1399.98px) {
  .form-search {
    width: 200px;
  }
}
.form-search input {
  position: relative;
  z-index: 1;
  padding-right: 2.5em;
  padding-left: 1em;
}
.form-search button {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 10;
  height: 100%;
  width: 2em;
  font-size: 1.375em;
  color: #A3A3A3;
}

.offcanvas-search {
  background-color: #F6F6F6;
  height: fit-content !important;
}
@media (max-width: 1199.98px) {
  .offcanvas-search {
    padding-top: var(--h-height);
  }
}
.offcanvas-search .offcanvas-body {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}
@media (max-width: 1199.98px) {
  .offcanvas-search .offcanvas-body {
    padding: 1.5em 20%;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-search .offcanvas-body {
    padding: 1.5em 15%;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-search .offcanvas-body {
    font-size: 1.3em;
    padding: 1.5em 10%;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-search .offcanvas-body {
    font-size: 1.2em;
    padding: 1em 5%;
  }
}
.offcanvas-search .offcanvas-body form {
  flex: 1;
}
.offcanvas-search .offcanvas-body .close {
  margin-left: 1em;
}

.offcanvas-menu {
  width: 100% !important;
  padding: var(--h-height) 0px 0px;
}
.offcanvas-menu .offcanvas-body {
  padding: 2em 0px;
}
.offcanvas-menu .offcanvas-body nav {
  background: url(imgs/bg/bg-gradient-2.jpg) no-repeat center;
  background-size: 100% 100%;
  font-size: 1.3em;
  padding: 1em;
  border-radius: 1.25em;
}
.offcanvas-menu .offcanvas-body nav ul li:not(:last-child) {
  border-bottom: 1px solid #FFFFFF;
  margin-bottom: 0.5em;
}
.offcanvas-menu .offcanvas-body nav ul li a {
  display: block;
  padding: 0.75em 0;
  width: 100%;
}

.sec-1 {
  margin-top: calc(var(--h-height) * -1);
  position: relative;
  height: 850px;
  padding-bottom: 10%;
}
@media (max-width: 1399.98px) {
  .sec-1 {
    padding-bottom: 12%;
  }
}
@media (max-width: 1199.98px) {
  .sec-1 {
    height: 650px;
    padding-bottom: 11%;
  }
}
@media (max-width: 991.98px) {
  .sec-1 {
    height: 500px;
    margin-top: 0px;
  }
}
@media (max-width: 767.98px) {
  .sec-1 {
    height: 100vw;
    padding-bottom: 20%;
  }
}
@media (max-width: 575.98px) {
  .sec-1 {
    height: 130vw;
    padding-bottom: 25%;
  }
}
.sec-1 svg {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.sec-1 svg .link-text {
  font-size: 1.25em;
  font-weight: 700;
  fill: #FFFFFF;
  transition: 300ms ease-in-out;
}
@media (max-width: 767.98px) {
  .sec-1 svg .link-text {
    font-size: 0.8em;
  }
}
@media (max-width: 575.98px) {
  .sec-1 svg .link-text {
    font-size: 0.9em;
  }
}
.sec-1 svg .link:hover .link-text {
  fill: #82c69e;
}
.sec-1 svg .round {
  fill: #000000;
  font-size: 1.3em;
  font-weight: 500;
  letter-spacing: 2.75px;
  transform-origin: 1409px 728px;
}
@media (max-width: 767.98px) {
  .sec-1 svg .round {
    font-size: 0.85em;
    letter-spacing: 1px;
    transform-origin: 258px 372px;
  }
}
@media (max-width: 575.98px) {
  .sec-1 svg .round {
    font-size: 1em;
  }
}
.sec-1 .container {
  position: relative;
  z-index: 10;
}
.sec-1 .btn-catalog {
  position: absolute;
  bottom: 2em;
  right: 17.7%;
  background-color: #2c2c2c;
  color: #fff;
  z-index: 20;
  width: 170px;
  height: 170px;
  border-radius: 50%;
  font-size: 1.25em;
  font-weight: 700;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}
.sec-1 .btn-catalog::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%) scale(1.4);
  background: url(/imgs/go.svg) no-repeat center;
  background-size: contain;
}

.sec-2 {
  margin-top: -18em;
  position: relative;
  z-index: 25;
  width: 60%;
}
@media (max-width: 1596.98px) {
  .sec-2 {
    width: 68%;
  }
}
@media (max-width: 1399.98px) {
  .sec-2 {
    width: 75%;
  }
}
@media (max-width: 1199.98px) {
  .sec-2 {
    margin-top: -12em;
  }
}
@media (max-width: 991.98px) {
  .sec-2 {
    width: 70%;
    margin-top: -10em;
  }
}
@media (max-width: 767.98px) {
  .sec-2 {
    width: 44%;
    margin-top: -16em;
  }
}
.sec-2 .product {
  font-size: 1.1em;
}
@media (max-width: 1199.98px) {
  .sec-2 .product {
    font-size: 0.9em;
  }
}

.sec-3 .grid {
  list-style: none;
  padding-left: 0px;
  display: grid;
  grid-template-columns: 4fr 2fr 4fr;
  grid-template-rows: 1fr;
  grid-template-areas: "area1 area2 area3";
  gap: 1.8em;
}
@media (max-width: 1399.98px) {
  .sec-3 .grid {
    gap: 1em;
  }
}
@media (max-width: 1199.98px) {
  .sec-3 .grid {
    gap: 1em;
    grid-template-columns: 4fr 2fr;
    grid-template-rows: auto;
    grid-template-areas: "area1 area2" "area3 area3";
  }
}
@media (max-width: 575.98px) {
  .sec-3 .grid {
    grid-template-columns: 5fr 3fr;
    font-size: 0.8em;
  }
}
.sec-3 .grid figure {
  position: relative;
}
.sec-3 .grid figure img {
  --height: 325px;
  width: 100%;
  height: var(--height);
  border-radius: 1.25em;
  object-fit: cover;
  object-position: center;
  box-shadow: 0px 8px 10px rgba(141, 141, 141, 0.05);
  margin-bottom: 1em;
  position: relative;
  z-index: 1;
}
@media (max-width: 1596.98px) {
  .sec-3 .grid figure img {
    --height: 300px;
  }
}
@media (max-width: 1399.98px) {
  .sec-3 .grid figure img {
    --height: 270px;
  }
}
@media (max-width: 991.98px) {
  .sec-3 .grid figure img {
    --height: 220px;
  }
}
@media (max-width: 767.98px) {
  .sec-3 .grid figure img {
    --height: 180px;
  }
}
@media (max-width: 575.98px) {
  .sec-3 .grid figure img {
    --height: 33vw;
  }
}
.sec-3 .grid figure figcaption {
  margin-bottom: 1em;
}
.sec-3 .grid figure figcaption h3, .sec-3 .grid figure figcaption .h3 {
  margin-bottom: 0px;
}
.sec-3 .grid-1 {
  grid-area: area1;
}
.sec-3 .grid-1 figure {
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: nowrap;
}
@media (max-width: 991.98px) {
  .sec-3 .grid-1 figure {
    height: 100%;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
  }
}
.sec-3 .grid-2 {
  grid-area: area2;
}
.sec-3 .grid-2 figure img {
  --height: 375px;
}
@media (max-width: 1596.98px) {
  .sec-3 .grid-2 figure img {
    --height: 350px;
  }
}
@media (max-width: 1399.98px) {
  .sec-3 .grid-2 figure img {
    --height: 315px;
  }
}
@media (max-width: 767.98px) {
  .sec-3 .grid-2 figure img {
    --height: 245px;
  }
}
@media (max-width: 575.98px) {
  .sec-3 .grid-2 figure img {
    --height: 44vw;
  }
}
.sec-3 .grid-2 figure figcaption {
  position: absolute;
  top: 0%;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 2;
  text-align: center;
  color: #FFFFFF;
  padding: 2em 1em;
}
.sec-3 .grid-3 {
  grid-area: area3;
}

.sec-4 .img-1 {
  width: 100%;
  height: auto;
  border-radius: 1.25em;
  object-fit: cover;
  object-position: center;
  box-shadow: 0px 8px 10px rgba(141, 141, 141, 0.05);
}
.sec-4 .img-2 {
  --height: 240px;
  width: 100%;
  height: var(--height);
  border-radius: 1.25em;
  object-fit: cover;
  object-position: center;
  box-shadow: 0px 8px 10px rgba(141, 141, 141, 0.05);
}
@media (max-width: 1596.98px) {
  .sec-4 .img-2 {
    --height: 210px;
  }
}
@media (max-width: 1399.98px) {
  .sec-4 .img-2 {
    --height: 180px;
  }
}
.sec-4 p {
  font-size: 1.25em;
}
@media (max-width: 1596.98px) {
  .sec-4 p {
    font-size: 1.1em;
  }
}
@media (max-width: 1399.98px) {
  .sec-4 p {
    font-size: 1em;
  }
}
.sec-4 .svg {
  width: 100%;
  height: auto;
}
.sec-4 .svg .round {
  fill: #FFFFFF;
  font-size: 1.2em;
  font-weight: 600;
  letter-spacing: 0.66px;
  transform-origin: 665px 156px;
}
@media (max-width: 767.98px) {
  .sec-4 .svg .round {
    font-size: 1.33em;
    letter-spacing: 1px;
  }
}
@media (max-width: 575.98px) {
  .sec-4 .svg .round {
    font-size: 1.53em;
  }
}
.sec-4 .svg .title {
  font-family: "Racama";
  fill: #FFFFFF;
  font-size: 4.375em;
  font-weight: 400;
}
.sec-4 .svg .text {
  font-size: 1.5em;
  font-weight: 400;
  fill: #FFFFFF;
}
@media (max-width: 767.98px) {
  .sec-4 .svg .text {
    font-size: 1.75em;
  }
}
@media (max-width: 575.98px) {
  .sec-4 .svg .text {
    font-size: 2em;
  }
}
@media (max-width: 767.98px) {
  .sec-4 h4, .sec-4 .h4 {
    width: 60%;
    margin-top: -1em;
  }
}

@-webkit-keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-moz-keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.sec-5 h2 img, .sec-5 .h2 img {
  width: 1.34em;
  vertical-align: baseline;
}
.sec-5 ul {
  font-size: 1.25em;
}
@media (max-width: 1199.98px) {
  .sec-5 ul {
    font-size: 1.1em;
  }
}
@media (max-width: 991.98px) {
  .sec-5 ul {
    font-size: 1em;
  }
}
.sec-5 ul li:not(:first-child) {
  margin-left: 2em;
}
@media (max-width: 1199.98px) {
  .sec-5 ul li:not(:first-child) {
    margin-left: 1em;
  }
}
.sec-5 .product img {
  --height: 330px;
}
@media (max-width: 1596.98px) {
  .sec-5 .product img {
    --height: 300px;
  }
}
@media (max-width: 1199.98px) {
  .sec-5 .product img {
    --height: 260px;
  }
}
@media (max-width: 575.98px) {
  .sec-5 .product img {
    --height: 40vw;
  }
}
.sec-5 .menu-slider {
  width: fit-content;
  max-width: 100%;
  margin: 0 auto 4em;
  padding: 2px 0;
}
.sec-5 .menu-slider .swiper-slide {
  width: fit-content;
}

.offers-slider {
  position: unset;
}
.offers-slider .swiper-button-prev,
.offers-slider .swiper-button-next {
  width: fit-content;
  height: fit-content;
  top: 50%;
  transform: translateY(-50%);
  margin: 0px;
}
@media (max-width: 767.98px) {
  .offers-slider .swiper-button-prev,
.offers-slider .swiper-button-next {
    display: none;
  }
}
.offers-slider .swiper-button-prev::after,
.offers-slider .swiper-button-next::after {
  color: #2C2C2C;
  font-size: 1.85em;
}
.offers-slider .swiper-button-prev {
  left: 4em;
}
@media (max-width: 1399.98px) {
  .offers-slider .swiper-button-prev {
    left: -0.7em;
  }
}
.offers-slider .swiper-button-next {
  right: 4em;
}
@media (max-width: 1399.98px) {
  .offers-slider .swiper-button-next {
    right: -0.7em;
  }
}

.sec-6 {
  margin-top: 15em;
}
@media (max-width: 1596.98px) {
  .sec-6 {
    margin-top: 10em;
  }
}
@media (max-width: 991.98px) {
  .sec-6 {
    position: relative;
  }
}
.sec-6 .box {
  position: relative;
  z-index: 1;
  background: url(/imgs/bg/bg-palegreen.jpg) no-repeat center;
  background-size: cover;
  border-radius: 1.25em;
  padding: 3em 9em 3em 30%;
  box-shadow: 0px 8px 10px rgba(141, 141, 141, 0.05);
}
.sec-6 .box.gradient {
  background: linear-gradient(93.57deg, rgba(51, 153, 171, 0.15) 1.66%, rgba(120, 216, 72, 0.15) 54.95%, rgba(145, 238, 143, 0.15) 91.91%, rgba(145, 238, 143, 0.15) 92.86%);
  background-size: 100% 100%;
}
@media (max-width: 1596.98px) {
  .sec-6 .box {
    padding: 3em 6em 3em 30%;
  }
}
@media (max-width: 1399.98px) {
  .sec-6 .box {
    padding: 3em 3em 3em 30%;
  }
}
@media (max-width: 1199.98px) {
  .sec-6 .box {
    padding: 2em 2em 2em 22%;
  }
}
.sec-6 .box form {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap;
  font-size: 1.2em;
}
@media (max-width: 1199.98px) {
  .sec-6 .box form {
    font-size: 1em;
  }
}
.sec-6 .box form input {
  width: 37%;
  margin-right: 3%;
}
@media (max-width: 991.98px) {
  .sec-6 .box form input {
    width: 100%;
    margin-right: 0%;
    margin-bottom: 1em;
    order: 1;
  }
}
.sec-6 .box form button {
  width: 20%;
}
@media (max-width: 991.98px) {
  .sec-6 .box form button {
    width: fit-content;
    order: 3;
    margin: 0 auto;
  }
}
.sec-6 .box form p {
  width: 100%;
  margin-top: 0.5em;
}
@media (max-width: 1199.98px) {
  .sec-6 .box form p {
    font-size: 0.9em;
  }
}
@media (max-width: 991.98px) {
  .sec-6 .box form p {
    order: 2;
    margin-top: 0px;
    margin-bottom: 1em;
  }
}
.sec-6:not(.no-img)::after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 6em;
  background: url(/imgs/bg/bg-palegreen.jpg) no-repeat center;
  background-size: cover;
  display: none;
}
@media (max-width: 991.98px) {
  .sec-6:not(.no-img)::after {
    display: block;
  }
}
@media (max-width: 991.98px) {
  .sec-6:not(.no-img) .box {
    background: unset;
    padding: 0 1em 1em;
    box-shadow: none;
  }
}
@media (max-width: 575.98px) {
  .sec-6:not(.no-img) .box {
    padding: 0.5em;
  }
}
@media (max-width: 991.98px) {
  .sec-6:not(.no-img) .box h2, .sec-6:not(.no-img) .box .h2 {
    font-size: 2em;
    margin-left: 15%;
    height: 3em;
    margin-bottom: 0px;
    display: flex;
    align-items: center;
  }
}
@media (max-width: 575.98px) {
  .sec-6:not(.no-img) .box h2, .sec-6:not(.no-img) .box .h2 {
    font-size: 1.5em;
    height: 4em;
    margin-left: 28%;
  }
}
@media (max-width: 991.98px) {
  .sec-6:not(.no-img) .box form {
    width: 80%;
    margin: 0 auto;
  }
}
@media (max-width: 575.98px) {
  .sec-6:not(.no-img) .box form {
    font-size: 1.2em;
  }
}
.sec-6 .img {
  position: absolute;
  z-index: 10;
  bottom: 0px;
  left: 1%;
  width: 30%;
}
@media (max-width: 1199.98px) {
  .sec-6 .img {
    left: -3%;
    width: 26%;
  }
}
@media (max-width: 991.98px) {
  .sec-6 .img {
    bottom: calc(100% - 6em);
    width: 20%;
  }
}
@media (max-width: 575.98px) {
  .sec-6 .img {
    left: 0%;
    width: 30%;
  }
}
.sec-6.no-img {
  margin-top: 0em;
}
.sec-6.no-img .box {
  padding: 2em;
}
@media (max-width: 1596.98px) {
  .sec-6.no-img .box form {
    font-size: 1.1em;
  }
}
.sec-6.no-img .box form input {
  margin-right: 2%;
}
@media (max-width: 1199.98px) {
  .sec-6.no-img .box form input {
    width: 35%;
  }
}
@media (max-width: 991.98px) {
  .sec-6.no-img .box form input {
    width: 100%;
  }
}
.sec-6.no-img .box form button {
  width: 22%;
}
@media (max-width: 1199.98px) {
  .sec-6.no-img .box form button {
    width: 26%;
  }
}
@media (max-width: 991.98px) {
  .sec-6.no-img .box form button {
    width: 100%;
  }
}

.sec-7 .review {
  background-color: #FFFFFF;
  color: #000000;
  border-radius: 1.25em;
  padding: 2em;
  font-family: "Racama";
  text-align: center;
  font-size: 1.1em;
}
@media (max-width: 1199.98px) {
  .sec-7 .review {
    font-size: 1em;
    padding: 1.5em;
  }
}
@media (max-width: 575.98px) {
  .sec-7 .review {
    font-size: 1.2em;
  }
}
.sec-7 .review .star-rating {
  margin-bottom: 1em;
}
.sec-7 .reviews-slider {
  position: unset;
  padding-bottom: 5em;
}
@media (max-width: 767.98px) {
  .sec-7 .reviews-slider {
    width: calc(100% - 6em);
    padding-bottom: 0em;
  }
}
.sec-7 .reviews-slider .swiper-slide-active .review {
  background-color: #2C2C2C;
  color: #FFFFFF;
}
.sec-7 .reviews-slider .swiper-button-prev,
.sec-7 .reviews-slider .swiper-button-next {
  width: 3em;
  height: 3em;
  top: unset;
  bottom: 0px;
  transform: unset;
  margin: 0px;
  border-radius: 0.625em;
  background-color: #2C2C2C;
  color: #FFFFFF;
}
@media (max-width: 1199.98px) {
  .sec-7 .reviews-slider .swiper-button-prev,
.sec-7 .reviews-slider .swiper-button-next {
    width: 2.5em;
    height: 2.5em;
  }
}
@media (max-width: 767.98px) {
  .sec-7 .reviews-slider .swiper-button-prev,
.sec-7 .reviews-slider .swiper-button-next {
    top: 50%;
    bottom: unset;
    transform: translateY(-50%);
  }
}
.sec-7 .reviews-slider .swiper-button-prev::after,
.sec-7 .reviews-slider .swiper-button-next::after {
  font-size: 1.3em;
  line-height: 130%;
}
.sec-7 .reviews-slider .swiper-button-prev {
  left: unset;
  right: calc(50% + 1.5em);
}
@media (max-width: 767.98px) {
  .sec-7 .reviews-slider .swiper-button-prev {
    left: 0px;
    right: unset;
  }
}
.sec-7 .reviews-slider .swiper-button-next {
  right: unset;
  left: calc(50% + 1.5em);
}
@media (max-width: 767.98px) {
  .sec-7 .reviews-slider .swiper-button-next {
    right: 0px;
    left: unset;
  }
}

.sec-8 {
  --offset: 13%;
}
@media (max-width: 991.98px) {
  .sec-8 {
    --offset: 0%;
  }
}
.sec-8 .container {
  position: relative;
}
.sec-8 .container .homuncule {
  position: absolute;
  bottom: 0px;
  left: 0px;
  z-index: 10;
  width: calc(var(--offset) + 3em);
  height: 100%;
  object-fit: contain;
  object-position: left bottom;
}
@media (max-width: 991.98px) {
  .sec-8 .container .homuncule {
    position: relative;
    bottom: unset;
    left: unset;
    width: 250px;
    height: 240px;
    object-fit: cover;
    object-position: center top;
    margin: 0 auto 0 35%;
  }
}
@media (max-width: 575.98px) {
  .sec-8 .container .homuncule {
    width: 170px;
    height: 160px;
    margin: 0 auto 0 30%;
  }
}
.sec-8 .container .title {
  background: url(/imgs/bg/bg-blue.jpg) no-repeat;
  background-size: cover;
  color: #FFFFFF;
  border-radius: 1.25em;
  padding: 1.7em 0em;
  padding-left: var(--offset);
  margin-bottom: 2em;
  display: block;
}
@media (max-width: 991.98px) {
  .sec-8 .container .title {
    padding: 1em;
  }
}
.sec-8 .quiz {
  --w: 200px;
  position: relative;
}
@media (max-width: 991.98px) {
  .sec-8 .quiz {
    background-color: #F6F6F6;
    border-radius: 1.25em;
    box-shadow: 0px 8px 10px rgba(141, 141, 141, 0.05);
    --w: 150px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    flex-wrap: wrap;
    padding: 2em;
  }
}
@media (max-width: 575.98px) {
  .sec-8 .quiz {
    padding: 1.25em;
    font-size: 0.9em;
  }
}
.sec-8 .quiz-pagination {
  position: absolute;
  top: 0px;
  left: var(--offset);
  width: var(--w);
  height: 50px;
}
@media (max-width: 991.98px) {
  .sec-8 .quiz-pagination {
    height: unset;
    width: 30%;
    position: relative;
  }
}
@media (max-width: 575.98px) {
  .sec-8 .quiz-pagination {
    width: 35%;
  }
}
@media (max-width: 991.98px) {
  .sec-8 .quiz-pagination h5, .sec-8 .quiz-pagination .h5 {
    margin-bottom: 0.5em;
  }
}
@media (max-width: 575.98px) {
  .sec-8 .quiz-pagination h5, .sec-8 .quiz-pagination .h5 {
    text-align: center;
  }
}
.sec-8 .quiz-pagination-bullets {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
}
@media (max-width: 575.98px) {
  .sec-8 .quiz-pagination-bullets {
    justify-content: center;
  }
}
.sec-8 .quiz-pagination-bullets div {
  background-color: rgba(79, 172, 118, 0.5);
  width: 0.625em;
  height: 0.625em;
  border-radius: 0.625em;
  transition: 300ms ease-in-out;
}
.sec-8 .quiz-pagination-bullets div:not(:first-child) {
  margin-left: 1em;
}
@media (max-width: 575.98px) {
  .sec-8 .quiz-pagination-bullets div:not(:first-child) {
    margin-left: 0.5em;
  }
}
.sec-8 .quiz-pagination-bullets div.active {
  background-color: #4fac76;
  width: 3em;
}
.sec-8 .quiz-top {
  margin-left: var(--offset);
  position: relative;
  z-index: 1;
  padding-left: var(--w);
  height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: nowrap;
}
@media (max-width: 991.98px) {
  .sec-8 .quiz-top {
    padding-left: 0.75em;
    height: unset;
    width: 70%;
  }
}
@media (max-width: 575.98px) {
  .sec-8 .quiz-top {
    width: 65%;
  }
}
.sec-8 .quiz-top h3, .sec-8 .quiz-top .h3 {
  margin-bottom: 0px;
}
@media (max-width: 991.98px) {
  .sec-8 .quiz-top h3, .sec-8 .quiz-top .h3 {
    font-size: 1.2em;
  }
}
.sec-8 .quiz-main {
  margin-left: calc(var(--offset) - 4em);
  position: relative;
  z-index: 1;
  background-color: #F6F6F6;
  border-radius: 1.25em;
  box-shadow: 0px 8px 10px rgba(141, 141, 141, 0.05);
  padding: 4em 8em;
  margin-top: 1em;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}
@media (max-width: 1596.98px) {
  .sec-8 .quiz-main {
    padding: 3em 5em;
  }
}
@media (max-width: 1199.98px) {
  .sec-8 .quiz-main {
    padding: 2em 1em;
    margin-left: var(--offset);
    font-size: 0.8em;
  }
}
@media (max-width: 991.98px) {
  .sec-8 .quiz-main {
    background-color: transparent;
    box-shadow: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 0px;
    margin-top: 2em;
    width: 100%;
  }
}
.sec-8 .quiz-main.last {
  position: relative;
}
.sec-8 .quiz-main.last .btn-6 {
  position: absolute;
  bottom: 1em;
  right: 1em;
  font-size: 1.2em;
}
@media (max-width: 991.98px) {
  .sec-8 .quiz-main.last .btn-6 {
    right: unset;
    left: 0px;
    bottom: 0px;
  }
}
.sec-8 .quiz-main.last .content {
  padding: 3em 0em;
}
@media (max-width: 991.98px) {
  .sec-8 .quiz-main.last .content {
    padding: 1em 0em 5em;
    width: 100%;
  }
}
@media (max-width: 991.98px) {
  .sec-8 .quiz-main.last .btn-1 {
    position: absolute;
    bottom: 0px;
    right: 0px;
  }
}
@media (max-width: 991.98px) {
  .sec-8 .quiz-main > ul {
    width: 100%;
    flex: auto;
    order: 1;
  }
}
@media (max-width: 991.98px) {
  .sec-8 .quiz-main > .btn-1, .sec-8 .quiz-main > .btn-6 {
    width: fit-content;
    order: 2;
    margin-top: 1em;
  }
}
@media (max-width: 767.98px) {
  .sec-8 .quiz-main > .btn-1, .sec-8 .quiz-main > .btn-6 {
    font-size: 1.35em;
  }
}
@media (max-width: 1596.98px) {
  .sec-8 .quiz-main h3, .sec-8 .quiz-main .h3 {
    font-size: 1.5em;
  }
}
.sec-8 .quiz-main .variant {
  width: 100%;
}
.sec-8 .quiz-main .variant-visual {
  width: 100%;
  transition: 300ms ease-in-out;
}
.sec-8 .quiz-main .variant-visual img {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 1.25em;
  border: 3px solid transparent;
  background-color: #FFFFFF;
  transition: 300ms ease-in-out;
}
@media (max-width: 1596.98px) {
  .sec-8 .quiz-main .variant-visual img {
    height: 200px;
  }
}
@media (max-width: 1199.98px) {
  .sec-8 .quiz-main .variant-visual img {
    height: 180px;
  }
}
@media (max-width: 767.98px) {
  .sec-8 .quiz-main .variant-visual img {
    height: 140px;
  }
}
@media (max-width: 575.98px) {
  .sec-8 .quiz-main .variant-visual img {
    height: 26vw;
  }
}
.sec-8 .quiz-main .variant input:checked + .variant-visual {
  color: #4FAC76;
}
.sec-8 .quiz-main .variant input:checked + .variant-visual img {
  border-color: #4FAC76;
}
.sec-8 .quiz-main .variant-2 {
  width: 100%;
}
.sec-8 .quiz-main .variant-2-visual {
  width: 100%;
  background-color: #FFFFFF;
  border: 1px solid #000000;
  border-radius: 1.25em;
  transition: 300ms ease-in-out;
  padding: 0.85em;
}
.sec-8 .quiz-main .variant-2 input:checked + .variant-2-visual {
  color: #FFFFFF;
  background-color: #4FAC76;
  border-color: #4FAC76;
}

.sec-9 {
  margin-top: 2em;
}
@media (max-width: 1399.98px) {
  .sec-9 .product {
    font-size: 1.1em;
  }
}
@media (max-width: 1199.98px) {
  .sec-9 .product {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    flex-wrap: nowrap;
    padding: 1em 2em;
  }
}
@media (max-width: 767.98px) {
  .sec-9 .product {
    padding: 1em;
  }
}
@media (min-width: 1597px) {
  .sec-9 .product img {
    --height: 300px;
  }
}
@media (max-width: 1199.98px) {
  .sec-9 .product img {
    --height: 200px;
    width: var(--height);
  }
}
@media (max-width: 575.98px) {
  .sec-9 .product img {
    --height: 100px;
  }
}
@media (max-width: 1199.98px) {
  .sec-9 .product figcaption {
    flex: 1;
    margin-left: 2em;
  }
}
@media (max-width: 575.98px) {
  .sec-9 .product figcaption {
    margin-left: 1em;
    margin-top: 0em;
  }
}
@media (max-width: 1199.98px) {
  .sec-9 .product-bottom {
    display: block;
  }
  .sec-9 .product-bottom button {
    margin-top: 2em !important;
  }
}
@media (max-width: 575.98px) {
  .sec-9 .product-bottom button {
    margin-top: 1em !important;
  }
}
.sec-9 .hit {
  position: absolute;
  top: -3.5em;
  right: -1em;
  z-index: 10;
  height: 150px;
  animation: rotation 30s infinite linear;
}
@media (max-width: 1596.98px) {
  .sec-9 .hit {
    height: 120px;
  }
}
@media (max-width: 1199.98px) {
  .sec-9 .hit {
    right: unset;
    top: -2.5em;
    left: -1em;
  }
}
@media (max-width: 575.98px) {
  .sec-9 .hit {
    height: 70px;
    top: -1.5em;
    left: 0em;
  }
}

.sec-10 .btn-5 {
  font-size: 1.5em;
  justify-content: flex-start;
  padding: 1.2em;
  height: 100%;
  text-align: left;
}
@media (max-width: 1399.98px) {
  .sec-10 .btn-5 {
    font-size: 1.25em;
  }
}
.sec-10 .icon {
  height: 100px;
  margin-bottom: 0.5em;
  object-fit: contain;
  width: 120px;
}
.sec-10 img {
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 0.8em;
}

.sec-12 {
  padding-top: 3em;
}
.sec-12 .subcategories {
  list-style: none;
  padding-left: 0px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}
@media (max-width: 991.98px) {
  .sec-12 .subcategories {
    width: 100%;
    margin-bottom: 1em;
  }
}
.sec-12 .subcategories li {
  margin-bottom: 0.5em;
}
.sec-12 .subcategories li:not(:last-child) {
  margin-right: 1em;
}
.sec-12 .box {
  position: relative;
  margin-top: 10em;
  background: url(imgs/bg/bg-green.jpg) no-repeat center;
  background-size: cover;
  padding: 2em;
  border-radius: 1.25em;
  color: #FFFFFF;
}
@media (max-width: 1399.98px) {
  .sec-12 .box {
    margin-top: 7em;
  }
}
@media (max-width: 767.98px) {
  .sec-12 .box {
    margin-top: 4em;
  }
}
.sec-12 .box::after {
  content: "";
  position: absolute;
  z-index: 2;
  top: -7em;
  right: 10%;
  width: 220px;
  height: 250px;
  background: url(imgs/man2.png) no-repeat center;
  background-size: contain;
}
@media (max-width: 1596.98px) {
  .sec-12 .box::after {
    right: 7%;
  }
}
@media (max-width: 1399.98px) {
  .sec-12 .box::after {
    right: 3%;
    top: -4em;
    width: 180px;
    height: 200px;
  }
}
@media (max-width: 1199.98px) {
  .sec-12 .box::after {
    content: unset;
  }
}
.sec-12 .box form {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: nowrap;
}
@media (max-width: 767.98px) {
  .sec-12 .box form {
    flex-direction: column;
    align-items: center;
  }
}
.sec-12 .box form input {
  flex: 1;
  margin-right: 1em;
}
@media (max-width: 767.98px) {
  .sec-12 .box form input {
    margin-right: 0em;
    margin-bottom: 1em;
  }
}
@media (max-width: 1399.98px) {
  .sec-12 .product {
    font-size: 1.1em;
  }
}
@media (max-width: 1199.98px) {
  .sec-12 .product {
    font-size: 1em;
  }
}
@media (max-width: 991.98px) {
  .sec-12 .product {
    font-size: 0.9em;
  }
}
@media (max-width: 767.98px) {
  .sec-12 .product {
    font-size: 1em;
  }
}
@media (min-width: 1597px) {
  .sec-12 .product img {
    --height: 300px;
  }
}

.form-filter {
  max-width: 300px;
  font-size: 1.25em;
}
@media (max-width: 1199.98px) {
  .form-filter {
    font-size: 1em;
  }
}
@media (max-width: 991.98px) {
  .form-filter {
    max-width: 100%;
  }
}
@media (max-width: 767.98px) {
  .form-filter {
    font-size: 1.1em;
  }
}
@media (max-width: 575.98px) {
  .form-filter {
    font-size: 1.2em;
  }
}
.form-filter fieldset legend {
  font-size: 1em;
  font-weight: 600;
  float: unset;
}
.form-filter ul {
  list-style: none;
  padding-left: 0px;
}
.form-filter input {
  border-color: #A3A3A3;
  border-radius: 0.25em;
  padding: 0.5em 1em;
}
.form-filter label input {
  padding: 0px;
  margin-right: 0.5em;
}
.form-filter ul li {
  margin-bottom: 0.75em;
}

@media (max-width: 991.98px) {
  .offcanvas-filter {
    padding: var(--h-height) 0px var(--f-height);
  }
}
.offcanvas-filter .offcanvas-body {
  position: relative;
}
@media (max-width: 991.98px) {
  .offcanvas-filter .offcanvas-body {
    padding: 2em 1em 1em;
  }
}
.offcanvas-filter .offcanvas-body .close {
  position: absolute;
  top: 0.5em;
  right: 0.8em;
  padding: 0.25em;
  border-radius: 0.25em;
  font-size: 1.25em;
}

.sec-13 {
  --sliders: 580px;
  display: inline-block;
}
@media (max-width: 1596.98px) {
  .sec-13 {
    --sliders: 480px;
  }
}
@media (max-width: 1399.98px) {
  .sec-13 {
    --sliders: 415px;
  }
}
@media (max-width: 1199.98px) {
  .sec-13 {
    --sliders: 350px;
  }
}
@media (max-width: 991.98px) {
  .sec-13 {
    --sliders: 600px;
  }
}
@media (max-width: 767.98px) {
  .sec-13 {
    --sliders: 420px;
  }
}
@media (max-width: 575.98px) {
  .sec-13 {
    --sliders: 75vw;
  }
}
.sec-13 .product-grid {
  --h: 150px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: var(--h) var(--sliders) auto;
  grid-template-areas: "area2 area1" "area2 area1" "area4 area3";
  gap: 20px 80px;
}
@media (max-width: 1596.98px) {
  .sec-13 .product-grid {
    gap: 20px 60px;
  }
}
@media (max-width: 1399.98px) {
  .sec-13 .product-grid {
    --h: 130px;
    gap: 20px 45px;
  }
}
@media (max-width: 991.98px) {
  .sec-13 .product-grid {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas: "area1" "area2" "area3" "area4";
  }
}
.sec-13 .product-grid > * {
  min-width: 0px;
}
.sec-13 .product-grid .area-1 {
  grid-area: area1;
}
.sec-13 .product-grid .area-2 {
  grid-area: area2;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: nowrap;
}
.sec-13 .product-grid .area-3 {
  grid-area: area3;
}
.sec-13 .product-grid .area-4 {
  grid-area: area4;
}
.sec-13 .thumbslides {
  --w: 100px;
  width: var(--w);
  height: var(--sliders);
}
@media (max-width: 1199.98px) {
  .sec-13 .thumbslides {
    --w: 80px;
  }
}
@media (max-width: 575.98px) {
  .sec-13 .thumbslides {
    --w: 50px;
  }
}
.sec-13 .thumbslides .swiper-slide {
  height: fit-content;
}
.sec-13 .thumbslides img {
  width: var(--w);
  height: var(--w);
  border-radius: 10px;
  object-fit: contain;
  object-position: center;
  background-color: #FFFFFF;
}
.sec-13 .mainslides {
  margin-left: 1.25em;
  flex: 1;
  background: url(imgs/bg/bg-gradient.jpg) no-repeat center;
  background-size: cover;
  padding: 1em;
  border-radius: 1.25em;
  height: var(--sliders);
  margin-bottom: 3em;
}
@media (max-width: 1199.98px) {
  .sec-13 .mainslides {
    margin-left: 1em;
  }
}
.sec-13 .mainslides img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: contain;
  object-position: center;
  background-color: #FFFFFF;
  border-radius: 1.25em;
}
.sec-13 .params-list {
  list-style: none;
  padding-left: 0px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: 2em;
}
.sec-13 .params-list li {
  margin-bottom: 0.5em;
}
.sec-13 .params-list li:not(:last-child) {
  margin-right: 1em;
}
.sec-13 .price-box {
  background-color: #F6F6F6;
  padding: 1.25em;
  border-radius: 1.25em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
}
.sec-13 .price-box:not(:last-child) {
  margin-bottom: 2em;
}
.sec-13 .price-box .price {
  font-size: 1.5em;
  font-weight: 700;
  color: #000000;
}
.sec-13 .price-box .info {
  color: #A3A3A3;
  font-size: 0.75em;
  font-weight: 500;
}
.sec-13 .set {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  border-bottom: 1px solid #F6F6F6;
  padding-bottom: 1em;
  margin-bottom: 1em;
}
.sec-13 .set-item {
  --w: 220px;
  width: var(--w);
}
@media (max-width: 1199.98px) {
  .sec-13 .set-item {
    --w: 200px;
  }
}
@media (max-width: 575.98px) {
  .sec-13 .set-item {
    --w: 135px;
  }
}
.sec-13 .set-item img {
  background: url(imgs/bg/bg-gradient.jpg) no-repeat center;
  background-size: 100% 100%;
  border-radius: 1.25em;
  padding: 5px;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  isolation: isolate;
  transform: translateZ(0);
  width: var(--w);
  height: var(--w);
  border-radius: 1.25em;
  object-fit: scale-down;
  object-position: center;
  margin-bottom: 0.5em;
}
.sec-13 .set-item h6, .sec-13 .set-item .h6 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.2em;
  height: 2.4em;
  margin-bottom: 1em;
}
.sec-13 .set-item-price {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
}
.sec-13 .set-item-price .old {
  color: #A3A3A3;
  text-decoration: line-through;
}
.sec-13 .set-item-price .new {
  color: #000000;
  font-weight: 600;
}
.sec-13 .set-icon {
  font-size: 2em;
  stroke-width: 1px;
  margin: 0 0.5em;
}
@media (max-width: 575.98px) {
  .sec-13 .set-icon {
    font-size: 1.5em;
  }
}
.sec-13 .text h3, .sec-13 .text .h3 {
  color: #A3A3A3;
  font-size: 1.6em;
  margin-bottom: 1em;
}
.sec-13 .text ul {
  list-style: none;
  padding-left: 0px;
  font-size: 1.2em;
}
.sec-13 .text ul li {
  margin-bottom: 0.5em;
}

.sec-14 .product {
  font-size: 1.15em;
}
@media (max-width: 1399.98px) {
  .sec-14 .product {
    font-size: 1.1em;
  }
}
@media (max-width: 1199.98px) {
  .sec-14 .product {
    font-size: 0.95em;
  }
}

.sec-15 .cart-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: nowrap;
}
@media (max-width: 767.98px) {
  .sec-15 .cart-item {
    align-items: center;
  }
}
.sec-15 .cart-item.service {
  align-items: center;
}
@media (max-width: 575.98px) {
  .sec-15 .cart-item.service {
    align-items: flex-start;
  }
}
.sec-15 .cart-item-img {
  --w: 200px;
  width: var(--w);
  height: var(--w);
  border-radius: 1.25em;
  object-fit: scale-down;
  object-position: center;
  padding: 10px;
  background: url(imgs/bg/bg-gradient.jpg) no-repeat center;
  background-size: cover;
}
@media (max-width: 1399.98px) {
  .sec-15 .cart-item-img {
    --w: 170px;
  }
}
@media (max-width: 575.98px) {
  .sec-15 .cart-item-img {
    padding: 7px;
    --w: 130px;
  }
}
.sec-15 .cart-item-info {
  flex: 1;
  margin-left: 1.25em;
  padding: 1em 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: nowrap;
}
@media (max-width: 575.98px) {
  .sec-15 .cart-item-info {
    margin-left: 0.75em;
    margin-top: 1em;
  }
}
.sec-15 .cart-item-info ul {
  list-style: none;
  padding-left: 0px;
  color: #A3A3A3;
  font-size: 1.2em;
}
@media (max-width: 767.98px) {
  .sec-15 .cart-item-info ul {
    display: none;
  }
}
.sec-15 .cart-item-info ul li {
  margin-bottom: 0.2em;
}
.sec-15 .cart-item-info .more {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  font-size: 1.25em;
}
.sec-15 .cart-item-info .more svg {
  background-color: #2C2C2C;
  color: #FFFFFF;
  font-size: 1.25em;
  padding: 0.15em;
  border-radius: 5px;
  margin-left: 0.5em;
  margin-right: 0.5em;
}
.sec-15 .box {
  position: relative;
  z-index: 1;
  background-color: #F6F6F6;
  padding: 1.5em;
  border-radius: 1.25em;
}
.sec-15 .cart-img {
  position: relative;
  z-index: 2;
  width: 33%;
  width: 35%;
  margin-left: -5%;
}
@media (max-width: 575.98px) {
  .sec-15 .cart-img {
    position: absolute;
    width: 120px;
    top: -60px;
    right: 0px;
  }
}

/* Section Blog */
.title {
  position: relative;
  background: url(/imgs/bg/bg-green.jpg) no-repeat;
  background-size: cover;
  color: #FFFFFF;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  border-radius: 1.25em;
  padding: 1.7em 2em;
}
.title.blog {
  --img: 4em;
  margin: var(--img) 0 calc(var(--img) + 3em);
}
@media (max-width: 1199.98px) {
  .title.blog {
    --img: 3em;
  }
}
@media (max-width: 991.98px) {
  .title.blog {
    --img: 2.5em;
  }
}
@media (max-width: 767.98px) {
  .title.blog {
    --img: 2em;
  }
}
@media (max-width: 575.98px) {
  .title.blog {
    --img: 1em;
  }
}
@media (max-width: 991.98px) {
  .title {
    padding: 1em 1.5em;
  }
}
.title h1, .title .h1,
.title h2,
.title .h2 {
  margin-bottom: 0px;
}
.title img {
  position: absolute;
  top: 50%;
  right: 5%;
  transform: translateY(-50%);
  width: 250px;
  height: calc(100% + var(--img) * 2);
  z-index: 10;
  object-fit: contain;
}
@media (max-width: 991.98px) {
  .title img {
    width: 200px;
  }
}
@media (max-width: 767.98px) {
  .title img {
    width: 150px;
  }
}
@media (max-width: 575.98px) {
  .title img {
    width: 90px;
  }
}

.news {
  position: relative;
}
.news img {
  --h: 315px;
  width: 100%;
  height: var(--h);
  border-radius: 1.25em;
  object-fit: cover;
  object-position: center;
}
@media (max-width: 1596.98px) {
  .news img {
    --h: 285px;
  }
}
@media (max-width: 1399.98px) {
  .news img {
    --h: 250px;
  }
}
@media (max-width: 991.98px) {
  .news img {
    --h: 220px;
  }
}
@media (max-width: 575.98px) {
  .news img {
    --h: 43vw;
  }
}
.news figcaption {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  margin-bottom: 1em;
}
.news figcaption h6, .news figcaption .h6 {
  font-size: 0.95em;
  font-weight: 500;
}
.news figcaption a {
  background-color: #2C2C2C;
  color: #FFFFFF;
  font-size: 1em;
  width: 1.5em;
  height: 1.5em;
  border-radius: 0.3125em;
  margin-left: 1em;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  transition: 300ms ease-in-out;
}
.news figcaption a:hover {
  background-color: #236840;
}

article .text img {
  width: 48%;
  float: right;
  border-radius: 2.5em;
  margin-left: 4em;
  margin-bottom: 2em;
}
@media (max-width: 1199.98px) {
  article .text img {
    border-radius: 1.25em;
    margin-left: 2em;
    margin-bottom: 1em;
  }
}
@media (max-width: 767.98px) {
  article .text img {
    width: 100%;
    float: right;
    margin-left: 0em;
    margin-bottom: 1.5em;
  }
}
article .text p {
  margin-bottom: 1.5em;
}

footer.desktop {
  height: var(--f-height);
  background-color: #1F1F1F;
  color: #FFFFFF;
}
@media (max-width: 1199.98px) {
  footer.desktop {
    font-size: 0.9em;
  }
}
footer.desktop ul {
  list-style: none;
  padding-left: 0px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
}
footer.desktop ul li:not(:first-child) {
  margin-left: 2em;
}
@media (max-width: 1199.98px) {
  footer.desktop ul li:not(:first-child) {
    margin-left: 1em;
  }
}
footer.desktop a {
  transition: 300ms ease-in-out;
  color: #FFFFFF;
}
footer.desktop a:hover, footer.desktop a:focus {
  color: #82c69e;
}
footer.desktop .bb-05 {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  line-height: 90%;
  display: inline-block;
}
footer.mobile {
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  z-index: 1040;
  height: var(--f-height);
  background: #FFFFFF;
  box-shadow: 0px 4px 20px rgba(7, 0, 87, 0.1);
}
footer.mobile nav {
  height: 100%;
}
footer.mobile nav ul {
  height: 100%;
  list-style: none;
  padding-left: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: nowrap;
}
footer.mobile nav ul li a,
footer.mobile nav ul li button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}
footer.mobile nav ul li a svg,
footer.mobile nav ul li button svg {
  font-size: 2em;
  padding: 0.1em;
  border-radius: 5px;
  stroke-width: 1.25px;
}
footer.mobile nav ul li a span,
footer.mobile nav ul li button span {
  font-size: 0.85em;
  color: #A3A3A3;
  margin-top: 0.2em;
}
footer.mobile nav ul li a.active svg,
footer.mobile nav ul li button.active svg {
  background-color: #4FAC76;
  color: #FFFFFF;
}

.error-message {
  position: absolute;
  bottom: 0;
  font-size: 12px;
  padding: 0 1.5rem;
  color: red;
}

.nav-pagination {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 2rem;
}
.nav-pagination ul {
  display: flex;
  gap: 1rem;
}
.nav-pagination ul li {
  /* Auto layout */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4px 10px;
  gap: 10px;
  width: 32px;
  height: 32px;
}
.nav-pagination ul li.active {
  background: rgba(79, 172, 118, 0.1);
  border-radius: 50%;
  list-style-type: none;
}

.cart-badge {
  position: absolute;
  color: white;
  background-color: black;
  border-radius: 50%;
  width: 1.2em;
  height: 1.2em;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  margin-left: 1em;
  margin-top: 1em;
}

.change-qty {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 10px;
  width: 172px;
  height: 40px;
}
.change-qty button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
  gap: 10px;
  width: 40px;
  height: 40px;
  /* white */
  background: #FFFFFF;
  /* green */
  border: 1px solid #4FAC76;
  border-radius: 10px;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  display: flex;
  align-items: center;
  text-align: center;
  /* green */
  color: #4FAC76;
}
.change-qty span {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 15px;
  gap: 10px;
  width: 72px;
  height: 40px;
  /* dark gray */
  border: 1px solid #8F8F8F;
  border-radius: 10px;
}

.submit-order {
  position: absolute;
  inset: var(--h-height) 0 var(--f-height);
  z-index: 100500;
  display: flex;
  justify-content: center;
}
.submit-order::before {
  content: "";
  position: absolute;
  inset: 0;
  background-color: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(10px);
}
.submit-order .form-box,
.submit-order .order-box {
  position: fixed;
  margin: 16vh 1em auto;
  background: url(imgs/bg/bg-green.jpg) no-repeat center;
  background-size: cover;
  padding: 2em;
  border-radius: 1.25em;
  color: #FFFFFF;
}
@media (max-width: 1399.98px) {
  .submit-order .form-box,
.submit-order .order-box {
    margin-top: 7em;
  }
}
@media (max-width: 767.98px) {
  .submit-order .form-box,
.submit-order .order-box {
    margin-top: 4em;
  }
}
.submit-order .form-box form,
.submit-order .order-box form {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: nowrap;
}
@media (max-width: 767.98px) {
  .submit-order .form-box form,
.submit-order .order-box form {
    flex-direction: column;
    align-items: center;
  }
}
.submit-order .form-box form input,
.submit-order .order-box form input {
  flex: 1;
  margin-right: 1em;
}
@media (max-width: 767.98px) {
  .submit-order .form-box form input,
.submit-order .order-box form input {
    margin-right: 0em;
    margin-bottom: 1em;
  }
}
.submit-order .form-box::after {
  content: "";
  position: absolute;
  z-index: 2;
  top: -7em;
  right: 10%;
  width: 220px;
  height: 250px;
  background: url(imgs/man2.png) no-repeat center;
  background-size: contain;
}
@media (max-width: 1596.98px) {
  .submit-order .form-box::after {
    right: 7%;
  }
}
@media (max-width: 1399.98px) {
  .submit-order .form-box::after {
    right: 3%;
    top: -4em;
    width: 180px;
    height: 200px;
  }
}
@media (max-width: 1199.98px) {
  .submit-order .form-box::after {
    content: unset;
  }
}

.close {
  font-size: 24px;
  position: absolute;
  right: 0.3em;
  top: 0.2em;
  color: white;
}

.nothing-found {
  position: relative;
  background: url(/imgs/bg/bg-green.jpg) no-repeat;
  background-size: cover;
  color: #FFFFFF;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  border-radius: 20px;
  padding: 30px;
  gap: 30px;
}
